var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-vehicle-attachment py-4"},[_c('v-row',[_c('v-col',{staticClass:"mx-4"}),_c('v-col',{staticClass:"shrink mx-4"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"success"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Dodaj załącznik")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.state.dialog),callback:function ($$v) {_vm.$set(_vm.state, "dialog", $$v)},expression:"state.dialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.state.isFormValid),callback:function ($$v) {_vm.$set(_vm.state, "isFormValid", $$v)},expression:"state.isFormValid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Dodawanie załącznika")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":"Załączniki","counter":"","rules":_vm.rules.attachments,"multiple":"","show-size":"","persistent-hint":"","hint":"hint","accept":_vm.fileTypesString},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var file = ref.file;
var index = ref.index;
var multiple = ref.multiple;
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":""}},[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("mdi-file-outline")]),_c('span',[_vm._v(_vm._s(text))]),_c('v-icon',{attrs:{"right":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.model.attachments.splice(index, 1)}}},[_vm._v("mdi-close")])],1)]}},{key:"message",fn:function(ref){
var key = ref.key;
var message = ref.message;
return [(message === 'hint')?_c('div',{staticClass:"pr-5",staticStyle:{"line-height":"1.75"}},[_vm._v("Użyj klawiszy "),_c('kbd',[_vm._v("Shift")]),_vm._v(" oraz "),_c('kbd',[_vm._v("Ctrl")]),_vm._v(" lub "),_c('kbd',[_vm._v("Command"),_c('v-icon',{attrs:{"right":"","x-small":"","dark":""}},[_vm._v("mdi-apple-keyboard-command")])],1),_vm._v(", aby wybrać wiele plików.")]):_c('div',{staticClass:"pr-5",staticStyle:{"line-height":"1.75"}},[_vm._v(_vm._s(message))])]}}]),model:{value:(_vm.model.attachments),callback:function ($$v) {_vm.$set(_vm.model, "attachments", $$v)},expression:"model.attachments"}})],1)],1)],1),_c('v-card-text',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"ml-4",attrs:{"text":""},on:{"click":function($event){_vm.state.dialog = false}}},[_vm._v("Anuluj")]),_c('v-btn',{staticClass:"ml-4",attrs:{"type":"submit","depressed":"","color":"success"}},[_c('span',[_vm._v("Dodaj")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1)],1)],1)],1)],1)],1),_c('v-card-text',[(_vm.attachments && _vm.attachments.length)?[_c('o-attachments',{attrs:{"ids":_vm.attachments.map(function (item) { return item.id; }),"deletable":""},on:{"update":_vm.fetch}}),(_vm.state.total > _vm.state.options.itemsPerPage)?_c('v-pagination',{staticClass:"mt-4",attrs:{"length":Math.ceil(_vm.state.total / _vm.state.options.itemsPerPage)},model:{value:(_vm.state.options.page),callback:function ($$v) {_vm.$set(_vm.state.options, "page", $$v)},expression:"state.options.page"}}):_vm._e()]:_c('v-alert',{staticClass:"ma-0",attrs:{"text":"","type":"info"}},[_vm._v("Brak załączników")])],2),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }