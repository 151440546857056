














































import useAttachment from "@/use/attachment";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    OAttachments: () => import("@/components/organisms/o-attachments.vue"),
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props, { root }) {
    const {
      fileTypesString,
      uploadAttachments,
      downloadAttachment,
      deleteAttachment,
    } = useAttachment({ root });
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;

    const form = ref<any>(null);
    const attachments = ref<any[]>([]);

    const state = reactive({
      loading: false,
      dialog: false,
      isFormValid: true,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      total: 0,
    });

    const model = reactive({
      attachments: [],
    });

    const rules = {
      attachments: [
        (v: File[]) => (!!v && !!v.length) || "Dodaj co najmniej jeden plik.",
        (v: File[]) =>
          !v || (v && v.length <= 5) || "Maksymalna ilość plików na raz to 5.",
      ],
    };

    const resetModel = () => {
      model.attachments = [];

      form.value.resetValidation();
    };

    const fetch = () => {
      const { page, itemsPerPage } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`vehicle/${props.id}/attachment`, {
          params: { page, itemsPerPage },
        })
        .then(({ data }) => {
          attachments.value = data.attachments;
          state.total = data.total;
        })
        .catch(() => {
          attachments.value = [];
          state.total = 0;
        })
        .finally(() => (state.loading = false));
    };

    watch(() => state.options, fetch, { deep: true });
    onMounted(fetch);

    const addAttachemnts = async () => {
      const attachmentData = {
        attachment: [],
      };

      if (model.attachments && model.attachments.length) {
        state.loading = true;

        await uploadAttachments(model.attachments)
          .then(({ data }) => {
            attachmentData.attachment = data.attachments.map(
              (attachment: { id: string }) => attachment.id
            );
          })
          .catch((error) => console.log(error));

        axiosInstance
          .post(`vehicle/${props.id}/attachment`, attachmentData)
          .then(() => {
            state.dialog = false;
            resetModel();
            fetch();
          })
          .catch(console.log)
          .finally(() => (state.loading = false));
      }
    };

    const onSubmit = async () => {
      await form.value.validate();
      if (state.isFormValid) addAttachemnts();
    };

    return {
      attachments,
      fileTypesString,
      downloadAttachment,
      deleteAttachment,
      form,
      state,
      model,
      rules,
      onSubmit,
      fetch,
    };
  },
});
